
.sa-content-fill{
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #091242 0%, rgba(9, 18, 66, 0.00) 100%);

}

.sa-tx-top{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}


.sa-main-tx{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}



.sa-tx-btm{
    display: flex;
width: auto;
flex-direction: column;
align-items: flex-start;
gap: 22px;
}

.sa-tx-btm div{
    display: flex;
    width: auto;
    align-items: center;
    gap: 15px;
}

.sa-tx-svg{
    width: 50px;

    height: 50px;

    flex-shrink: 0;
}







